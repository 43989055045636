import React from 'react';


export default class VirtualKeyboardListener extends React.Component {

  constructor() {
    super();
    this.listenersSettled = false;
  }

  componentDidUpdate() {

    var mobile = require('is-mobile');

    if(!mobile()) return;
    if(this.listenersSettled) return;

    this.listenersSettled = true;
    
    var allInputs = document.getElementsByTagName('input');
    var inputList = Array.prototype.slice.call(allInputs);
    
    inputList.forEach((value,index,ar) => {

      if(value === document.activeElement) {
        this.props.listener(true);
      }
      
      value.onfocus = () => {
        this.props.listener(true);
      }

      value.onblur = () => {
        this.props.listener(false);
      }
    });
  }

  render() {    
    return null;
  }
}

