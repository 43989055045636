export class CookieManager {

	constructor() { 
		this.restoreCookies();
	}

	setCookie(reg, val) {
		this.cookieObject[reg] = val;
    	document.cookie = reg + "=" + val;
	}

	hasCookie(reg) {
		return (reg in this.cookieObject);
	}

	deleteCookie(reg) {
	    delete this.cookieObject[reg];
	    document.cookie = reg + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
	}

	getCookie(reg) {
		return this.cookieObject[reg];
	}

	printCookies() {
	    console.log("-------------COOKIES");
	    for (var key in this.cookieObject) {
	      console.log(key, " - ", this.cookieObject[key]);
	    }
	    console.log("--------------------");
	}

	restoreCookies() {
	    this.cookieObject = {};
	    var ca = document.cookie.split(";");
	    for (var i = 0; i < ca.length; i++) {
	      var c = ca[i].trim();

	      var indexEq = c.indexOf("=");
	      var regAct = c.substr(0, indexEq);
	      var valAct = c.substr(indexEq + 1);

	      this.cookieObject[regAct] = valAct;
	    }
	}
}

export let cookieManager = new CookieManager();