
import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


//Los botones siguen la siguiente estructura, si el boton tiene callback entonces se llama el mismo
//pasandole como parametro el label, si no se le pasa callback simplemente cierra el modal
//buttons: [{label:'OK',callback:window.temp},{label:'Cancel'}]
class FWModalWindow extends React.Component {

  handleClose = (evt) => {
    this.closeModalWindow();  
  }
  
  closeModalWindow = () => {
    this.props.onClose();
  }

  render() {
    const { modalWindow } = this.props;

    let showTitle = modalWindow.title !== '' && modalWindow.title !== undefined;

    let buttons = null;

    if(modalWindow.buttons!==undefined && modalWindow.buttons.length>0) {
      buttons=[];

      for (var i = modalWindow.buttons.length - 1; i >= 0; i--) {        
        let currentCallback = this.handleClose;
        let modalRef = this;

        if(modalWindow.buttons[i].callback) {
          currentCallback = (function() {            
            modalRef.handleClose();
            this.callback(this.label);
          }).bind(modalWindow.buttons[i])
        }
        
        buttons.unshift(<Button key={'butMod'+i} onClick={currentCallback} color="secondary" autoFocus>{modalWindow.buttons[i].label}</Button>);                
      }
    }
    else {
      buttons=[<Button key='butMod1' onClick={this.handleClose} color="secondary" autoFocus>{"OK"}</Button>];
    }

    if((modalWindow.title==="" || modalWindow.title===undefined) && (modalWindow.message==="" || modalWindow.message===undefined)) return null;

    return (
      <React.Fragment>        
        <Dialog
          open={true}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {showTitle && <DialogTitle id="alert-dialog-title">{modalWindow.title}</DialogTitle>}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {modalWindow.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {buttons}
          </DialogActions>
        </Dialog>
      </React.Fragment>      
    );
  }
}  
  
FWModalWindow.propTypes = {
  modalWindow: PropTypes.object.isRequired,
};

export default (FWModalWindow);
