import React from "react";
import PropTypes from 'prop-types';

import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';

import logo from './logo_orange.png';


const divFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const styleBut = {
  fontSize:'2em',
  minWidth: '8px',
  padding: "0.1em 0.2em",
  lineHeight: "1em",
  color: "#ff5722",
  cursor:'pointer',
}

export class ActiveCell extends React.Component {
  
  static propTypes = {
    value: PropTypes.bool.isRequired,    
  };

  render() {
    const { value } = this.props;
    
    return (
      <div>
        {value?<DoneIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>}
      </div>
    );
  }
}

export class MemberCell extends React.Component {
  
  static propTypes = {
    value: PropTypes.bool.isRequired,    
  };

  render() {
    const { value } = this.props;
    
    return (
      <div>
        {value?<img src={logo} alt="logo" style={{width:'2em',height:'2em'}} />:''}
      </div>
    );
  }
}


export class SeeMoreCell extends React.Component {
  
  render() {
    return (
      <div style={divFlex}>
        <Button variant="contained" color="default" onClick={(ev) => this.props.fn(this.props.data)} style={styleBut}>
          +
        </Button>        
      </div>
    );
  }
} 