import { requestPostSail } from '../FwUtils/requester'
import { cookieManager } from './CookieManager'

export class SailsController {

	constructor() {  
		this.token = cookieManager.getCookie('token');		
	}

	closeSession() {

		var payload = {token:this.token};

		requestPostSail( process.env.REACT_APP_URL_SERVICES + 'console/closeSession', payload, 
			(data) => {
				console.log('Cerra sesion?',data);				
			}
		);

		cookieManager.deleteCookie('token');
		this.token = "";
	}

	retrieveUserInfo() {
		return new Promise((resolve, reject) => {
			if(!this.token) {
				this.closeSession();
				reject({cause:'noToken'});
			}

			var payload = {token:this.token};

			requestPostSail( process.env.REACT_APP_URL_SERVICES + 'console/getConsoleUserData', payload, 
				(data) => {
					//console.log('Llega data',data);
					if(data) {
						if(data.success) {
							resolve({data:data.data,account:data.account});
						}
						else {
							reject({cause:'invalidToken'});	
						}
					}
					else {
						reject({cause:'error'});
					}
				}
			);

		});
	}


	retrieveUserSessions(data) {
		return new Promise((resolve, reject) => {
			var payload = data;

			console.log('Payload',data);

			requestPostSail( process.env.REACT_APP_URL_SERVICES + 'console/getConsoleUserSessions', payload, 
				(data) => {
					console.log('Llega data',data);
					if(data) {
						if(data.success) {
							resolve({data:data.data});
						}
						else {
							reject({cause:data.message});	
						}
					}
					else {
						reject({cause:'error'});
					}
				}
			);

		});
	}

	/**
	 * Try to login a user in sails
	 *
	 * @param {string}   userData 	object with keys user and pass, example: {user:"myuser",pass:"mypass"}.
	 * @param {string}   pass 	password.
	 */
	loginAttemp(userData,callback) {

		//console.log('Enviando ',userData);
	
		var payload = userData;

		requestPostSail( process.env.REACT_APP_URL_SERVICES + 'console/login', payload, 
			(data) => {				
				//Si responde con el token de sesion, entonces lo guarda
				if(data.token!==undefined) {
					this.token = data.token;
					cookieManager.setCookie("token",this.token);					
				}

				if(callback!==undefined) {callback(data)}
			});
	}

	/*
		Para cambiar un usuario de activo a inactivo
	 */
	changeUserStatus(data) {

		data.token = this.token;

		return new Promise((resolve, reject) => {
			var payload = data;

			//Este dato no es necesario para el servidor, solo es para retornarlo luego al Dashboard, mas abajo
			//delete payload.indexInTable;
				
			requestPostSail( process.env.REACT_APP_URL_SERVICES + 'console/changeInstitutionalUserStatus', payload, 
				(serverData) => {
					if(serverData) {
						if(serverData.success) {
							//serverData.indexInTable = data.indexInTable;
							resolve(serverData.message);
						}
						else {
							reject({cause:serverData.message});	
						}
					}
					else {
						reject({cause:'error'});
					}
				}
			);

		});
	}


	/*
		Funcion para crear un nuevo usuario y agregarlo al cliente institucional
	 */	
	createNewUser(data) {
		data.token = this.token;

		return new Promise((resolve, reject) => {
			var payload = data;

			//Este dato no es necesario para el servidor, solo es para retornarlo luego al Dashboard, mas abajo
			//delete payload.indexInTable;
				
			requestPostSail( process.env.REACT_APP_URL_SERVICES + 'console/createNewInstitutionalUser', payload, 
				(serverData) => {
					if(serverData) {
						if(serverData.success) {
							console.log('Recibe',serverData);
							//serverData.indexInTable = data.indexInTable;
							resolve(serverData.data);
						}
						else {
							reject({cause:serverData.message,type:serverData.type});	
						}
					}
					else {
						reject({cause:'error'});
					}
				}
			);

		});
	}

	/*
		Funcion para crear un nuevo usuario y agregarlo al cliente institucional
	 */	
	updateInstitutionalUser(data) {
		data.token = this.token;

		return new Promise((resolve, reject) => {
			var payload = data;

			//Este dato no es necesario para el servidor, solo es para retornarlo luego al Dashboard, mas abajo
			//delete payload.indexInTable;
				
			requestPostSail( process.env.REACT_APP_URL_SERVICES + 'console/updateInstitutionalUser', payload, 
				(serverData) => {
					console.log('ServerData',serverData);
					if(serverData) {
						if(serverData.success) {
							console.log('Recibe',serverData);
							//serverData.indexInTable = data.indexInTable;
							resolve(serverData.data);
						}
						else {
							reject({cause:serverData.message,type:serverData.type});	
						}
					}
					else {
						reject({cause:'error'});
					}
				}
			);

		});
	}

	/*
		Para borrar un usuario perteneciente a una institucion
	 */
	deleteInstitutionalUser(data) {

		data.token = this.token;

		return new Promise((resolve, reject) => {
			var payload = data;

			//Este dato no es necesario para el servidor, solo es para retornarlo luego al Dashboard, mas abajo
			//delete payload.indexInTable;
				
			requestPostSail( process.env.REACT_APP_URL_SERVICES + 'console/deleteInstitutionalUser', payload, 
				(serverData) => {
					if(serverData) {
						console.log('Llega serverData',serverData);
						if(serverData.success) {
							//serverData.indexInTable = data.indexInTable;
							resolve(serverData);
						}
						else {
							reject({cause:serverData.message});	
						}
					}
					else {
						reject({cause:'error'});
					}
				}
			);

		});
	}
	
}

export let sailsController = new SailsController();