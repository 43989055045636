import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CardMedia from '@material-ui/core/CardMedia';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';

import moment from 'moment'

import { sailsController } from './controller/SailsController'
//import { sailsBulkData } from './controller/SailsBulkData'

import FwModalLoader from './FwUtils/FwModalLoader';
import FwModalWindow from './FwUtils/FwModalWindow';

import { toHHMMSS } from './FwUtils/dateUtils';

import MUIDataTable from "mui-datatables";

import { ActiveCell,SeeMoreCell,MemberCell } from "./DashboardCells";
import DashboardTableToolbar from "./DashboardTableToolbar";
import DashboardNewUser from "./DashboardNewUser";
import AppBarAdmin from "./AppBarAdmin";

import BackIcon from '@material-ui/icons/KeyboardBackspace';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import {FIELDS} from './fields';


/*const columns = ["Nombre", "Documento", "Correo electrónico", "Sesiones", "Activo"];

const tableOptions = {
  filterType: 'checkbox',
};*/

/*
  No se si es un bug de la libreria de tablas pero cuando el espacio es menor a 995px
  se desaparecen las casillas de seleccion
 */
const themeTable = createMuiTheme({
  overrides: {
      MUIDataTableSelectCell: {
        root: {
          display: "table-cell !important"
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          //textAlign: "center",
        }
      },
      MuiTableCell: {
        root: {
         padding: "4px 0px 4px 24px",
         //textAlign: "center",
        }
      }
    },
    typography: {
      useNextVariants: true,
    },
});

const columnsSession = [
  {name: "Fecha",
    options: {
      sortDirection: 'desc'
    },
    type: 'date',
  },
  {name: "Presentación"},
  {name: "Conectados"},
  {name: "Duración"},  
];

const styles = theme => ({ 

  mainCointainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1",    
  },

  divContent: {
    //display: "flex",
    padding: "20px",
    borderRadius: "4px",
    justifyContent: "center",
    backgroundColor: "#eeeeee",    
    flex: 1,    
    overflow: "hidden",    
  },

  divLogo: {
    marginLeft: "auto",
  },

  logo: {
    width: theme.spacing.unit*8,
    height: theme.spacing.unit*8,
  },

  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing.unit * 2,    
    margin: "auto",
    display: "flex",
    marginBottom: theme.spacing.unit * 2,
    alignItems: "center",
  },

  totalSessions: {
    marginLeft: theme.spacing.unit
  },

  clientLogo: {
    width: theme.spacing.unit * 16,
    height: theme.spacing.unit * 16,
    marginLeft: "auto",
  },

  divToAnimate: {
    /*width: "100%",*/
    position: "relative",
  },

  divBackButton: {
    flex: "1",
    paddingLeft: theme.spacing.unit * 2,
  },

  divUserData: {
    flex: "8 1 0%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },

  menuButton: {
    marginLeft: -theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },

  flexRow: {
    display: 'flex',    
  },

  button: {
    marginTop: theme.spacing.unit * 3,
  }, 

  divSessions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
});



class Dashboard extends Component {

  columns = [
    {name: "Sesiones",
      options: {
        sortDirection: 'desc'
      }
    },
    {name: "Nombre"},
    {name: "Documento"},
    {name: "Correo electrónico"},
    {name: "Cuenta Flipwit",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <MemberCell
              value={value}            
            />
          );
        },        
      },
    },
    {name: "Activo",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ActiveCell
              value={value}            
            />
          );
        },        
      },
    },
    {name: "Ver sesiones",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SeeMoreCell data={tableMeta} fn={this.onRowClick} />
          );
        }, 
      }
    }
  ];

  columnsSuperadmin = [
    {name: "Ultima sesión",
      options: {
        sortDirection: 'desc'
      },
      type:'date'
    },    
    {name: "Sesiones"},
    {name: "Nombre"},
    {name: "Correo electrónico"},
    {name: "Nombre de usuario"},
    {name: "Ver sesiones",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SeeMoreCell data={tableMeta} fn={this.onRowClick} />
          );
        }, 
      }
    }
  ];

  tableOptions = {
    filter: false,    
    selectableRows: true,
    customSort: (data, colIndex, order) => {return this.dateSort(data, colIndex, order)},
    responsive: 'scroll',
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <DashboardTableToolbar openUserEdition={this.openUserEdition} deleteInstitutionalUser={this.deleteInstitutionalUser} changeUserStatus={this.changeUserStatus} selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />
      ),
    //onRowClick: (rowData, rowMeta) => {this.onRowClick(rowData,rowMeta)},
    textLabels: {
      body: {
        noMatch: "No se encuentran usuarios con los parámetros dados",
        toolTip: "Click para ordenar",
      },
      pagination: {
        next: "Siguiente página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtar tabla",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar Columnas",
      },
      selectedRows: {
        text: "fila(s) seleccionada(s)",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    }
  };

  tableSessionsOptions = {
    filter: false,
    selectableRows: false,
    responsive: 'scroll',
    customSort: (data, colIndex, order) => {return this.dateSort(data, colIndex, order)},
    //onRowClick: (rowData, rowMeta) => {this.onRowClick(rowData,rowMeta)},
    textLabels: {
      body: {
        noMatch: "No se encuentran sesiones",
        toolTip: "Click para ordenar",
      },
      pagination: {
        next: "Siguiente página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtar tabla",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "REINICIAR",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar Columnas",
      },
      selectedRows: {
        text: "fila(s) seleccionada(s)",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    }
  };

  state = {
    showLoader:true,
    modalWindowInfo:undefined,
    users:[],
    urlLogo:undefined,
    totalSessions:"--",
    posX: 0,
    contentExpanded: false,
    dataRetrieved: false,   
    currentUser:{}, 
    currentUserDataRetrieved: false,
    currentUserSessions: {},
    showNewUser:false,
    superadmin:false,
    validatedSessions:undefined,
    userToEdit:undefined,
    dataIndexEdition: 0,
    dirtyUsers:true,
  }

  dateSort = (data, colIndex, order) => {        
    //console.log(">>",this.columsToUse,data, colIndex, order);
    let useDateSort = this.columsToUse[colIndex].type === 'date';

    //console.log('sort por',useDateSort);
    
    return data.sort((a, b) => {
      
      let valRet = 0;
      
      if(useDateSort) {
        a = a.data[colIndex]==="---"?new Date(-8640000000000000):new Date(a.data[colIndex]);
        b = b.data[colIndex]==="---"?new Date(-8640000000000000):new Date(b.data[colIndex]);
        
        if(a>b) valRet = -1;
        if(a===b) valRet = 0;
        if(a<b) valRet = 1;
      }
      else {
        if(isNaN(a.data[colIndex])) {
          a = "" + a.data[colIndex];
          b = "" + b.data[colIndex];          
          valRet = a.localeCompare(b)*-1;
        }
        else {
          a = Number(a.data[colIndex]);
          b = Number(b.data[colIndex]);    

          if(a>b) valRet = -1;
          if(a===b) valRet = 0;
          if(a<b) valRet = 1;   
        }
      }
      
      if(order==="desc") valRet*=-1;
      
      return valRet;          
    });
  }

  showLoader = (show=true) => this.setState({showLoader:show})

  constructor() {
    super();
    this.TWEEN = require('@tweenjs/tween.js');   
    this.animate();     
    this.fixingDiv2 = false;    
  }

  openModalWindow = (title,message,buttons) => {
    this.setState({modalWindowInfo:{title:title,message:message,buttons:buttons},showLoader:false});    
  }

  goToHome = () => {
    this.props.history.push('/');
  }

  closeSession = () => {
    sailsController.closeSession();
    this.goToHome();
  }

  addNewUser = () => {
    this.setState({showNewUser:true,userToEdit:undefined,dataIndexEdition: 0})
  }

  toLocaleString = (date) => {
    return moment(date).format('YYYY-MM-DD hh:mm:ss a');
    /*return date.toLocaleString("ja", {
       day:"2-digit",month:"2-digit",year: "numeric", hour:"2-digit",minute:"2-digit",second:"2-digit"
    });*/
  }

  alphabeticalSort = (a, b) => {
    return a.name.localeCompare(b.name);   
  }

  componentDidMount() {

    //sailsBulkData.uploadBulkData();

    sailsController.retrieveUserInfo()
      .then((data) => {
        this.showLoader(false);

        //console.log('data',data);

        //data.data.sort(this.alphabeticalSort);

        /*

        var sortBy = require('lodash/sortBy');

        data.data = sortBy(data.data, ['name']);*/

        var totalSessions = 0;

        var usersRetrieved = data.data.map(user => {
          totalSessions+=user.sessions;

          if(!data.account.superadmin) {
            return [user.sessions,user.name,user.document,user.email,user.hasFlipwitAccount,user.active,''];
          }
          else {
            //convierte la fecha
            let lastSessionDate =  user.lastSessionDate===""?"---":this.toLocaleString(new Date(user.lastSessionDate));
            return [lastSessionDate,user.sessions,user.name,user.email,user.username,'']; 
          }
        });

        //console.log('Gran total',totalSessions);
        //console.log('Va a dejar',usersRetrieved);
        this.setState({superadmin:data.account.superadmin,dataRetrieved:true,users:usersRetrieved,dirtyUsers:true,urlLogo:data.account.urlLogo,totalSessions:totalSessions});
      })
      .catch((cause) => {
        switch(cause.cause) {
          case 'noToken': this.goToHome(); break;
          case 'invalidToken': {
              sailsController.closeSession();
              this.openModalWindow("Error","Tu sesión ha expirado",[{label:'OK',callback:this.goToHome}]); 
              break;
            }
          case 'error': this.openModalWindow("Error","Ha ocurrido un error en el sistema, intenta mas tarde o si el problema persiste contacta al administrador"); break;
          default: break;
        }
      })
  }

  animate = (time) => {
      window.requestAnimationFrame(this.animate);
      this.TWEEN.update(time);
  }

  componentDidUpdate() {

    if(this.state.dataRetrieved) {
      this.setPosCotainers();      
    } 
    
    if(this.state.contentExpanded) {        
      if(this.fixingDiv2) return;

      var innerWidth = window.innerWidth;

      var div1 = document.getElementById('div1');
      var div2 = document.getElementById('div2');

      var style = window.getComputedStyle(div1, null);
      var widthDiv1 = style.getPropertyValue("width");
      div2.style.width = widthDiv1;

      div2.style.top = div1.offsetTop + "px";
      div2.style.left = innerWidth + "px";

      this.fixingDiv2 = true;

      this.left = div1.offsetLeft

      //console.log('innerWidth es ',innerWidth,'letf',this.left );

      var coords = { x: this.left }; 
      this.tween = new this.TWEEN.Tween(coords) 
          .to({ x: (-innerWidth+this.left) }, 800) 
          .easing(this.TWEEN.Easing.Back.InOut) 
          .onUpdate(() => {               
              div1.style.left = coords.x + "px";
              div2.style.left = (coords.x + innerWidth) + "px";

          })
          .start();     
    }
    
  }

  setPosCotainers = () => {

    var container = document.getElementById('divContainer');

    //Si aún no ha creado el container
    if(container===null) return;

    if(container.style.height!==undefined) return;

    //Mira el alto del componente y lo coloca directamente como el height para que 
    //posteriormente no se vaya a modificar
    var style = window.getComputedStyle(container, null);
    var heightContainer = style.getPropertyValue("height");
    container.style.height = heightContainer;
  }

  closeExpandedInfo = () => {
    var div1 = document.getElementById('div1');
    var div2 = document.getElementById('div2');

    this.fixingDiv2 = false;
    
    var left = this.left;
    
    var coords = { x: -window.innerWidth+left }; 
    this.tween = new this.TWEEN.Tween(coords) 
        .to({ x: 0 }, 800) 
        .easing(this.TWEEN.Easing.Back.InOut) 
        .onUpdate(() => { 
            //this.setState({posX:coords.x})
            div1.style.left = coords.x + "px";
            div2.style.left = (coords.x + window.innerWidth) + "px";

        })
        .start()
        .onComplete(()=> {
          this.setState({contentExpanded:false});
        }); // Start the tween immediately.*/    
  }

  //function(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => void
  onRowClick = (data) => {

    let rowData = data.rowData;
    //console.log('Clickea a ',rowData,data);
    
    this.fixingDiv2 = false;
    this.setState({});

    let sessions = rowData[0];
    let name = rowData[1];
    let document = rowData[2];
    let email = rowData[3];

    if(this.state.superadmin) {
      sessions = rowData[1];
      name = rowData[2];
      document = "";
      email = rowData[3];      
    }

    let currentUser = {name,document,email,sessions}; 

    this.setState({contentExpanded:true,currentUser:currentUser,showLoader:true,currentUserSessions:[]});

    let dataPayload = {email,superadmin:this.state.superadmin};

    if(this.state.superadmin) {
      //De los datos puros que llegaron del servidor, se obtiene segun la fila representada por este
      //objeto, el nombre de usuario correspondiente.
      dataPayload.username = rowData[4];      
    }

    sailsController.retrieveUserSessions(dataPayload)
      .then((data) => {

        //console.log('dataSessions',data);

        let dataToProcess = data.data;
        let validatedSessions = undefined;

        //Si es administrador sustrae la informacion de las sesiones para que el comportamiento del superadministrador
        //sea igual al de los otros usuarios, esto ya que en data como tal viene otra informacion importante
        if(this.state.superadmin) {
          validatedSessions = data.data.validatedSessions;
          dataToProcess = data.data.sessions;
        }

        var sortBy = require('lodash/sortBy');
        dataToProcess = sortBy(dataToProcess, ['initServerTime']);

        //console.log('Mapear a ',dataToProcess);

        var usersRetrieved = dataToProcess.map(session => {

          //Si tiene la variable initServerTime coloca esa como la fecha, como antes de Febrero de 2019 no se reportaba entonces las
          //sesiones que no tienen esa se le coloca el createdAt
          var dateSession = session.initServerTime?this.toLocaleString(new Date(session.initServerTime)):this.toLocaleString(new Date(session.createdAt));

          //Lo mismo con la duración, antes no se reportaba
          var duration = session.durationInSeconds?toHHMMSS(session.durationInSeconds):"No reportada";
          var presences = session.presencesCreated.length;

          //"Fecha","Presentación","Conectados","Duración",  
          return [dateSession,session.name,presences,duration];
        });

        //console.log('Gran total',totalSessions);
        this.setState({currentUserDataRetrieved:true,currentUserSessions:usersRetrieved,showLoader:false,validatedSessions});
      })
      .catch((cause) => {
        this.openModalWindow("Error","Ha ocurrido un error en el sistema, intenta mas tarde o si el problema persiste contacta al administrador ["+cause+"]");
      })

    /*var div1 = document.getElementById('div1');
    var div2 = document.getElementById('div2');
    var left = div1.offsetLeft
    
    var coords = { x: left }; 
    var tween = new this.TWEEN.Tween(coords) 
        .to({ x: (-window.innerWidth+left) }, 500) 
        .easing(this.TWEEN.Easing.Back.InOut) 
        .onUpdate(() => { 
            //this.setState({posX:coords.x})
            div1.style.left = coords.x + "px";
            div2.style.left = (coords.x + window.innerWidth) + "px";

        })
        .start(); // Start the tween immediately.*/    
    
  }

  //El primer parametro es el array con los datos del usuario, el segundo es el indice dentro de la tabla de todos los usuarios, para que cuando
    //se edite se sepa donde cambiar la informacion
  openUserEdition = (userToEdit,dataIndexEdition) => {
    
    this.setState({showNewUser:true,userToEdit,dataIndexEdition});
    //console.log('Para editar a ',userToEdit);
  }

  deleteInstitutionalUser = (users) => {


    //console.log('Borrar a ',users);

    let allGood = true;

    users.forEach(function(currentUser) {
      if(currentUser[FIELDS.NO_ADMIN.SESSION]>0) {
        allGood = false;
      }
    });

    if(!allGood) {
      this.openModalWindow("Error","Lo sentimos, No puedes eliminar usuarios que ya hayan tenido sesiones.");
    }
    else {
      this.showLoader(true);
      let changePromises = [];

      changePromises = users.map((data)=> {
      return new Promise((resolve, reject) => {
        sailsController.deleteInstitutionalUser({email:data[FIELDS.NO_ADMIN.EMAIL]})
        .then((dataRet) => {

            //console.log('Borró con',data[FIELDS.NO_ADMIN.POS_IN_TABLE]);
            /*let newData = this.state.users;
            
            console.log('new',newData[data[7]]);
            //En la posicion 6 viene el index de la fila en el array del estado
            //y la posicion 4 es la columna de activo
            newData[data[7]][5] = newStatus;

            this.setState({users:newData})*/
            resolve(data[FIELDS.NO_ADMIN.POS_IN_TABLE]);
        })
        .catch((cause) => {
            //console.log('Rechaza acá',cause);
            reject(cause.cause);
        })
      });      
    });
  
    Promise.all(changePromises).then((indexRemoved) => {
      indexRemoved.sort((a,b)=>a-b);
      
      let newData = this.state.users;

      for (var i = indexRemoved.length - 1; i >= 0; i--) {        
        newData.splice(indexRemoved[i],1)        
      }
      
      this.setState({users:newData,dirtyUsers:true})
      this.showLoader(false);      
    })
    .catch((cause) => {
      console.log(cause);
      this.openModalWindow("Error","Ha ocurrido un error en el sistema, intenta mas tarde o si el problema persiste contacta al administrador ["+cause+"]");
      this.showLoader(false);
    })
    
  }

    /*changePromises = users.map((data)=> {
      return new Promise((resolve, reject) => {
        sailsController.deleteInstitutionalUser({email:data[3]})
        .then((dataRet) => {
            let newData = this.state.users;
            
            console.log('new',newData[data[7]]);
            //En la posicion 6 viene el index de la fila en el array del estado
            //y la posicion 4 es la columna de activo
            newData[data[7]][5] = newStatus;

            this.setState({users:newData})                
            resolve();
        })
        .catch((cause) => {
            console.log('Rechaza acá',cause);
            reject(cause.cause);
        })
      });      
    });*/

    /*Promise.all(changePromises).then(() => {
      this.showLoader(false);
    })
    .catch((cause) => {
      console.log(cause);
      this.openModalWindow("Error","Ha ocurrido un error en el sistema, intenta mas tarde o si el problema persiste contacta al administrador ["+cause+"]");
      this.showLoader(false);
    })*/
  }

  changeUserStatus = (users,newStatus) => {

    //console.log(users,newStatus);
    let changePromises = [];
    this.showLoader(true);

    changePromises = users.map((data)=> {
      //Si el usuario ya tiene el estado que quiere poner entonces no hace nada
      //solo hace cuando tiene estado diferente
      if(data[5].props.value!==newStatus) {
          return new Promise((resolve, reject) => {
            sailsController.changeUserStatus({email:data[3],active:newStatus})
            .then((dataRet) => {
                let newData = this.state.users;
                
                //Esto de abajo ya no se usa porque el render pinta directamente lo del array
                //del estado entonces se cambia es el objeto del estado
                //---------------------------------------------------------------
                //Como data es pasado por referencia desde el toolbar, entonces es
                //la informacion que se muestra como tal en la tabla, en la posicion
                //4 es un componente de tipo active cell por lo que se reemplaza por el nuevo
                //para poder cambiar visualmente el estado del usuario
                //data[4] = <ActiveCell value={newStatus} />;
                

                //console.log('new',newData[data[7]]);
                //En la posicion 6 viene el index de la fila en el array del estado
                //y la posicion 4 es la columna de activo
                newData[data[7]][5] = newStatus;

                this.setState({users:newData,dirtyUsers:true})                
                resolve();
            })
            .catch((cause) => {
                console.log('Rechaza acá',cause);
                reject(cause.cause);
            })
          });
      }
      else {
        return true;
      }
    });

    Promise.all(changePromises).then(() => {
      this.showLoader(false);
    })
    .catch((cause) => {
      console.log(cause);
      this.openModalWindow("Error","Ha ocurrido un error en el sistema, intenta mas tarde o si el problema persiste contacta al administrador ["+cause+"]");
      this.showLoader(false);
    })
  }

  /*  
    Funcion invocada cuando un usuario se esta creando o editando, es la misma funcion ya que ambas son invocadas desde el mismo formulario
  */
  onUserCreatedOrEdited = (data) => {
    if(data.cancel) {
      this.setState({showNewUser:false,userToEdit:undefined,dataIndexEdition: 0});
    }
    else if(data.error) {
      console.log('dataError',data);
      this.openModalWindow("Error","Ha ocurrido un error al intentar crear el usuario ["+data.cause+"], por favor intenta mas tarde o contacta al administrador");
    }
    else {
      //console.log("creado",data);
      
      let newAllData = this.state.users;
      let actionPerformed = data.isEdited?'modificado':'creado';

      //Si el usuario es editado
      if(data.isEdited) {
        let currentEditData = newAllData[this.state.dataIndexEdition];
        currentEditData[FIELDS.NO_ADMIN.NAME] = data.name;
        currentEditData[FIELDS.NO_ADMIN.DOCUMENT] = data.document;
        currentEditData[FIELDS.NO_ADMIN.EMAIL] = data.email;
        currentEditData[FIELDS.NO_ADMIN.HAS_FLIPWIT_ACCOUNT] = data.hasFlipwitAccount;

        newAllData[this.state.dataIndexEdition] = currentEditData;
        
        //console.log('Datos editados',currentEditData);
      }
      //Si el usuario fue creado
      else {
        newAllData.push([0,data.name,data.document,data.email,data.hasFlipwitAccount,data.active,''])
      }

      var sortBy = require('lodash/sortBy');

      //console.log('Antes',newAllData);
      newAllData = sortBy(newAllData, ['name']);
      
      //console.log('Deja newAllData con',newAllData);
      this.setState({showNewUser:false,users:newAllData,dirtyUsers:true,userToEdit:undefined,dataIndexEdition: 0});
      this.openModalWindow("Usuario creado","El usuario "+data.name+` ha sido ${actionPerformed} con exito.`); 
    }
  }

  componentWillUpdate = (nextProps, nextState) => {    
    if(nextState.dirtyUsers) {
      //Toca crear una variable temporal para que si se agregan nuevas columnas funcione, si se coloca 
      //directamente this.state.users entonces no funciona. y esta temporal solo se refresca cuando se marca el estado como 
      //dirty, para que al cambiar otras cosas como por ejemplo un modal, no se refresque de nuevo toda la tabla    
      this.newData = nextState.users.slice();
      //console.log('this.newData a ',this.newData);
      this.setState({dirtyUsers:false});
    }
  }

  render() {
    const { classes } = this.props;
    
    this.columsToUse = this.state.superadmin?this.columnsSuperadmin:this.columns;

    let optionsToUse = this.tableOptions;

    if(this.state.superadmin) {
      optionsToUse.selectableRows = false;
    }

    return (
      <div className={classes.mainCointainer}>    
        {this.state.modalWindowInfo && <FwModalWindow modalWindow={this.state.modalWindowInfo} onClose={() => this.setState({modalWindowInfo:undefined})}/>}  
        {this.state.showLoader && <FwModalLoader />}
        {this.state.showNewUser && <DashboardNewUser onUserCreatedOrEdited={this.onUserCreatedOrEdited} userToEdit={this.state.userToEdit} />}
        <AppBarAdmin showMenu closeSession={this.closeSession} addNewUser={this.addNewUser} />
        {this.state.dataRetrieved && <div id="divContainer" className={classes.divContent}>          
          <div id="div1" className={classes.divToAnimate} /*style={{left:this.state.posX}}*/>
            <Paper className={classes.paper} elevation={1}>
    
              <div className={classes.divSessions}>
                <div className={classes.flexRow}>
                  <Typography variant="h6" component="h3">
                    Sesiones totales:
                  </Typography>   

                  <Typography variant="h5" component="h3" className={classes.totalSessions}>
                    {this.state.totalSessions}
                  </Typography>                         
                </div>
                {!this.state.superadmin && <Button variant="contained" color="primary" className={classes.button} onClick={() => this.addNewUser()}>
                    Activar nuevo usuario
                </Button>}
              </div>
              
              <CardMedia
                id="imgPresentationMode"
                className={classes.clientLogo}
                image={this.state.urlLogo}             
              />              

            </Paper>
            
            <MuiThemeProvider key="theme" theme={themeTable}>
              <MUIDataTable
                key="tablaPpal"
                title={"Usuarios asociados"}
                data={this.newData}
                columns={this.columsToUse}
                options={optionsToUse}
                style={{backgroundColor:'red'}}
              />            
            </MuiThemeProvider>
          </div>

          {this.state.contentExpanded && <div id="div2" /*className={classes.divToAnimate}*/ style={{position:"absolute"}}>
            <Paper className={classes.paper} elevation={1}>
              <div className={classes.divBackButton}>
                <Fab
                  color="primary"
                  aria-label="Back"
                  onClick={this.closeExpandedInfo}
                  className={classes.menuButton}
                >
                  <BackIcon style={{ fontSize: 30 }} />
                </Fab>
              </div>
              <div className={classes.divUserData}>              
                <Typography variant="h5" component="h3" className={classes.totalSessions}>
                  {this.state.currentUser.name}
                </Typography>   
                <Typography variant="h6" component="h3">
                  {this.state.currentUser.document}
                </Typography>  
                <Typography variant="subtitle1" component="h3">
                  {this.state.currentUser.email}
                </Typography>  
                <Typography variant="subtitle1" component="h3">
                  {"Total sesiones: " + this.state.currentUser.sessions}
                </Typography> 
                {this.state.validatedSessions && <Typography variant="subtitle1" component="h3">
                  {"Sesiones validadas: " + this.state.validatedSessions}
                </Typography>}
              </div>
            </Paper>

            <MUIDataTable
              key="tableSession"
              title={"Sesiones del usuario"}
              data={this.state.currentUserSessions}
              columns={columnsSession}
              options={this.tableSessionsOptions}
              style={{backgroundColor:'red'}}
            />            
          </div>}
        </div>}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(Dashboard));
