import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import { Delete, IndeterminateCheckBox, CompareArrows} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';

//import './Login.css';

const showDelete = true;

const styles = theme => ({ 

  itemToolbar: {
    marginRight: theme.spacing.unit * 4,
  },  
  inverseIcon: {
    transform: "rotate(90deg)",
  },
  editButton: theme.colors.indigo,
  activeButton: theme.colors.green,
  unactiveButton: theme.colors.red,
  toolbar: {
    display: 'flex',
    alignItems: "center",
    marginRight: "3px",
  }
 
});

class DashboardTableToolbar extends Component {

  handleClickInverseSelection = () => {
    const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!this.props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    this.props.setSelectedRows(nextSelectedRows);
  };

  handleClickEditButton = () => {        
    //La fila trae el indice y la propiedad displayData trae toda la info de los usuarios en la vista
    //console.log('Datos de edicion',this.props.displayData,this.props.selectedRows.data[0]);

    //El primer parametro es el array con los datos del usuario, el segundo es el indice dentro de la tabla de todos los usuarios, para que cuando
    //se edite se sepa donde cambiar la informacion
    this.props.openUserEdition(this.props.displayData[this.props.selectedRows.data[0].index],this.props.selectedRows.data[0].dataIndex);
  };

  deleteInstitutionalUser = () => {
    let userData = [];

    userData = this.props.selectedRows.data.map((data)=>{
      let arrInfo = this.props.displayData[data.index].data.slice(0);
      arrInfo.push(data.dataIndex)

      //Esto es para cambiar directamente el grafico pero ya no se utiliza
      //this.props.displayData[data.index].data[4] = <ActiveCell value={false} />;
      //return this.props.displayData[data.index].data;      
      
      return arrInfo;
    })
    
    this.props.deleteInstitutionalUser(userData);
  };

  handleClickChangeStatus = (newStatus) => {    
    let userData = [];

    userData = this.props.selectedRows.data.map((data)=>{
      let arrInfo = this.props.displayData[data.index].data.slice(0);
      arrInfo.push(data.dataIndex)

      //Esto es para cambiar directamente el grafico pero ya no se utiliza
      //this.props.displayData[data.index].data[4] = <ActiveCell value={false} />;
      //return this.props.displayData[data.index].data;      
      
      return arrInfo;
    })
    
    this.props.changeUserStatus(userData,newStatus);
  };

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  render() {
    const { classes } = this.props;

    let showEditButton = this.props.selectedRows.data.length===1;

    var {hasActive,hasUnactive} = this.props.selectedRows.data.reduce((selectedTypes,currentRow) => {      
      //Si tiene el flag de activo en true
      if(this.props.displayData[currentRow.index].data[5].props.value) {
        selectedTypes.hasActive = true;
      }
      else {
        selectedTypes.hasUnactive = true;
      }
      
      return selectedTypes;         
    },{});

    //console.log('Queda',hasActive,'y unac ',hasUnactive);

    return (
      <div className={classes.toolbar}>
        <Tooltip title={"Deseleccionar todos"}>
          <IconButton className={classes.itemToolbar} onClick={this.handleClickDeselectAll}>
            <IndeterminateCheckBox />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Invertir selección"}>
          <IconButton className={classes.itemToolbar} onClick={this.handleClickInverseSelection}>
            <CompareArrows className={classes.inverseIcon} />
          </IconButton>
        </Tooltip>
        {showDelete && <Tooltip title={"Borrar usuario"}>
          <IconButton className={classes.itemToolbar} onClick={this.deleteInstitutionalUser}>
            <Delete />
          </IconButton>
        </Tooltip>}
        {showEditButton && <Tooltip title={"Editar usuario"}>
          <Button variant="contained" color="primary" className={classnames(classes.editButton,classes.itemToolbar)} onClick={() => {this.handleClickEditButton()}}>
            EDITAR
          </Button>
        </Tooltip>} 
        {hasUnactive && <Tooltip title={"Activar usuario"}>
          <Button variant="contained" color="primary" className={classnames(classes.activeButton,classes.itemToolbar)} onClick={() => {this.handleClickChangeStatus(true)}}>
            ACTIVAR
          </Button>
        </Tooltip>}        
        {hasActive && <Tooltip title={"Desactivar usuario"}>
          <Button variant="contained" color="primary" className={classnames(classes.unactiveButton,classes.itemToolbar)} onClick={() => {this.handleClickChangeStatus(false)}}>
            DESACTIVAR
          </Button>
        </Tooltip>}
      </div>
    );
  }
}

DashboardTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(DashboardTableToolbar));
