const request = require('superagent');

/**
 * Hit the companylookup endpoint with the proper query.
 * Return a promise that resolves with the response.
 */
export function requestPostSail( url, payload, callback ) {
  /*if( !platformHandler.isElectron){
    require('es6-promise').polyfill();
    require('isomorphic-fetch');
  }*/

  request
    .post(url)
    .send(payload)
    // .set('accept', 'json')
    .end((err, res) => {
      if(err) {
        console.error('Ooops!! Houston, tenemos problemas!! nuestros servidores están caidos :(', err);
        return callback(err);
      }

      if(res && res.body) {
        callback(res.body);
      } else {
        console.error('Ooops!! Houston, tenemos problemas!! nuestros servidores no responden :(', err);
        callback({});
      }
    });

}