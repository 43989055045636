import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';


import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import VirtualKeyboardListener from "./FwUtils/VirtualKeyboardListener";


import { sailsController } from './controller/SailsController';

import {FIELDS} from './fields';


/*const columns = ["Nombre", "Documento", "Correo electrónico", "Sesiones", "Activo"];

const tableOptions = {
  filterType: 'checkbox',
};*/


const styles = theme => ({ 

  modalWn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',         
  },    

  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing.unit * 4,      
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "calc(100% - 96px)",
    minHeight: 150,
  },

  divTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: `calc( 100% + ${theme.spacing.unit * 4}px )`,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 0px",
    [theme.breakpoints.up('sm')]: {
      width: `calc( 100% + ${theme.spacing.unit * 6}px )`,
    },
  },

  divSubTitle: {
    display: "flex",
    alignSelf: "flex-start",
    marginTop: theme.spacing.unit * 2,
  },

  formContainer: {
    marginTop: theme.spacing.unit,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  button: {
    marginTop: theme.spacing.unit * 3,
  }, 

  invisibleButton: {
    height: "1px",
    padding: "0px",
    overflow: "hidden",
    visibility: "hidden",
  },

  divContent: {
    overflowY: 'auto',
    width: "90%",
  },

  buttonCancel: {
    ...theme.colors.red,
    marginRight: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },

  editButton: theme.colors.indigo,
});

const errorText = "Por favor completa el campo";
const emailErrorText = "Correo electrónico invalido";

class DashboardNewUser extends Component {

  state = {
    name: '',
    email: '',    
    id:'', 
    keyboardOpened:false,  
    errors:{name:false,email:false,id:false},
    edition:false,
    original_email:undefined, //Guarda el email inicial del usuario a editar, para que el server sepa quien era, en caso de una edicion de correo electronico
  }

  componentWillMount() {
    //console.log('Ejecuta will',this.props);
    //Si es una edición de usuario
    if(this.props.userToEdit!==undefined) {
      let data = this.props.userToEdit.data;
      this.setState({edition:true,name:data[FIELDS.NO_ADMIN.NAME],id:data[FIELDS.NO_ADMIN.DOCUMENT],email:data[FIELDS.NO_ADMIN.EMAIL],original_email:data[FIELDS.NO_ADMIN.EMAIL]})
      //console.log('Editar',this.props.userToEdit);
    }
  }

  componentDidMount() {
    //this.setState({name:"pepe",id:"165",email:Math.floor(Math.random()*1000000)+"@gmail.com"})
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });    
  };

  changeErrorState = (fieldName,value) => {
    let tempErrors = this.state.errors;
    tempErrors[fieldName] = value;
    this.setState({errors:tempErrors});
  }

  validateField = (fieldName,errorText) => {
    return new Promise((resolve, reject) => {
      if(this.state[fieldName]==="") {
        this.changeErrorState(fieldName,errorText)
        return reject(this.state.errors);        
      }

      this.changeErrorState(fieldName,false);
      resolve();
    })
  }

  validateEmailField = (fieldName,errorText) => {
    return new Promise((resolve, reject) => {
      if(/\S+@\S+\.\S+/.test(this.state[fieldName])===false) {
        this.changeErrorState(fieldName,errorText)        
        return reject(this.state.errors);
      }

      this.changeErrorState(fieldName,false);
      resolve();
    })
  }

  validateAllFields = () => {    
    var promises = [];

    promises.push(this.validateField('id',errorText));
    promises.push(this.validateField('name',errorText));
    promises.push(this.validateField('email',errorText));
    promises.push(this.validateEmailField('email',emailErrorText));

    return Promise.all(promises);
  }

  onSubmit = (ev) => {
    ev.preventDefault();

    this.validateAllFields().then(() => {
      this.setState({errors:{}})
      
      //Si es una edicion de usuario
      if(this.state.edition) {
        let data = require('lodash/pick')(this.state,['name','email','id','original_email'])

        sailsController.updateInstitutionalUser(data).then((editedUser)=> {
          editedUser.isEdited = true;
          console.log('Va a aceptar a ',editedUser);
          this.props.onUserCreatedOrEdited(editedUser);
        })
        .catch((err) => {
          console.log('err',err);
          if(err.type==='NO_EMAIL_CHANGE') {
            this.setState({errors:{email:err.cause}})
          } else {
            this.props.onUserCreatedOrEdited({error:true,cause:err.cause});          
          }
        })
      }
      //Si es una creación de uno nuevo
      else {
        let data = require('lodash/pick')(this.state,['name','email','id'])
        sailsController.createNewUser(data).then((newUser)=> {
          this.props.onUserCreatedOrEdited(newUser);
        })
        .catch((err) => {
          if(err.type==='EMAIL') {
            this.setState({errors:{email:err.cause}})
          } else {
            this.props.onUserCreatedOrEdited({error:true,cause:err.cause});          
          }
        })        
      }

    }).catch((errors) => {
      this.setState({errors})
    });

    console.log('Submitea');
  }

  componentDidUpdate() {
    /*var _ = require('lodash');
    var allInputs = document.getElementsByTagName('input');
  
    var inputList = Array.prototype.slice.call(allInputs);
    //console.log(inputList.length);
    inputList.forEach((value,index,ar) => {
      console.log(value);
      value.onfocus = () => {
        console.log('Se hizo foco');
      }

      value.onfocus = () => {
        console.log('Se hizo foco');
      }
    });*/

    /*for (var i = allInputs.length - 1; i >= 0; i--) {
      console.log(allInputs['length']);
    }*/

    //console.log(allInputs,allInputs.length);


    /*const children = [...document.getElementsByTagName('input')];
    children.forEach((child) => { console.log('chil'); });*/

    /*inputs.forEach(function(element) {
      console.log(element);
      element.onfocus = () => {
        console.log('Focus en uno');
      }
    });*/
  }

  listenerKeyboard = (keyboardOpened) => {
    console.log('keyboardOpened',keyboardOpened);
    this.setState({keyboardOpened})
  }

  render() {    
    const { classes } = this.props;
    
    var titleStyle = this.state.keyboardOpened?{height: "0px",overflow: "hidden"}:{};

    return (
      <React.Fragment>
        <VirtualKeyboardListener listener={this.listenerKeyboard} />
        <Modal
            open={true}          
            aria-labelledby="alert-create-user"
            aria-describedby="alert-create-user"
            classes={{ root: classes.modalWn }}                   
          >
            <Paper className={classes.paper} elevation={1} style={this.state.keyboardOpened?{paddingTop:2}:{}}>
              <div id="Title" style={titleStyle}>
                <Typography variant="h5" color="inherit" component="h3">
                  Activar nuevo usuario institucional
                </Typography>   
              </div>
              <div id="Content" className={classes.divContent}>
                <form className={classes.formContainer}>                
                  <FormControl>              
                    <TextField
                      id="standard-name"
                      autoFocus
                      error ={!!this.state.errors.name}
                      helperText={this.state.errors.name}
                      label="Nombre"
                      value={this.state.name}
                      onChange={this.handleChange('name')}
                      margin="normal"
                    />
                  </FormControl>         
                  <FormControl>              
                    <TextField
                      id="standard-document"
                      label="Documento de identidad"
                      error ={!!this.state.errors.id}
                      helperText={this.state.errors.id}
                      value={this.state.id}
                      onChange={this.handleChange('id')}
                      margin="normal"
                    />
                  </FormControl>    
                  <FormControl>              
                    <TextField
                      id="standard-mail"
                      label="Correo electrónico"
                      error ={!!this.state.errors.email}
                      helperText={this.state.errors.email}
                      value={this.state.email}
                      onChange={this.handleChange('email')}
                      margin="normal"
                    />
                  </FormControl>          
                  <Button type="submit" className={classes.invisibleButton} onClick={(ev) => this.onSubmit(ev)}>
                    Invisible Button Only For Submit
                  </Button>  
                </form>     
              </div>
              <div id="Action">
                <Button variant="contained" color="primary" className={classes.buttonCancel} onClick={(ev) => this.props.onUserCreatedOrEdited({cancel:true})}>
                  Cancelar
                </Button>
                {!this.state.edition && <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={(ev) => this.onSubmit(ev)}>
                  Activar usuario
                </Button>}
                {this.state.edition && <Button className={classnames(classes.editButton,classes.button)} type="submit" variant="contained" color="primary" onClick={(ev) => this.onSubmit(ev)}>
                  Guardar usuario
                </Button>}
              </div>
            </Paper>
        </Modal>    
      </React.Fragment>
    );
  }
}

DashboardNewUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(DashboardNewUser));
