import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import './index.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import Login from './Login';
import Dashboard from './Dashboard';
import * as serviceWorker from './serviceWorker';


const theme = createMuiTheme({
  palette: {
    primary: deepOrange,    
    secondary: indigo,
  },
  typography: {
    useNextVariants: true,
  },
  colors: {
    indigo: {
      backgroundColor: indigo[500],
      '&:hover': {
        backgroundColor: indigo[700],
      },
    },
    red: {
      backgroundColor: red[600],
      '&:hover': {
        backgroundColor: red[800],
      },
    },
    green: {
      backgroundColor: green[600],
      '&:hover': {
        backgroundColor: green[800],
      },
    },
  },
});

const routing = (
  <Router>
  	<MuiThemeProvider theme={theme}>
    	<React.Fragment>
	      <Route exact path="/" component={Login} />
	      <Route path="/dashboard" component={Dashboard} />        	      
	    </React.Fragment>
    </MuiThemeProvider>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
