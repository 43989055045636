export const FIELDS = {
    NO_ADMIN: {
        SESSION: 0,
        NAME: 1,
        DOCUMENT: 2,
        EMAIL: 3,
        HAS_FLIPWIT_ACCOUNT: 4,
        ACTIVE: 5,   
        POS_IN_TABLE: 7,     
    }
}