import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

const styles = theme => ({  
    modalWn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',         
    },         

    circular: {
      outline: "0px solid transparent",
    }
});

class FwModalLoader extends React.Component {

  render() {
    const { classes } = this.props;
    
    //let showTitle = modalWindow.title != '' && modalWindow.title != undefined;

    return (
      <React.Fragment>        
        <Modal
          open={true}          
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ root: classes.modalWn }}    
          disableAutoFocus={true}      
        >
          <CircularProgress size={50} className={classes.circular} />
        </Modal>
      </React.Fragment>      
    );
  }
}  

export default (withStyles(styles,{ withTheme: true })(FwModalLoader));
