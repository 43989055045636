import React from "react";
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import logo from './logo.png';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const drawerWidth = 240;

const styles = theme => ({ 
  divLogo: {
    marginLeft: "auto",
  },

  logo: {
    width: theme.spacing.unit*8,
    height: theme.spacing.unit*8,
  },  

  menuButton: {
    marginLeft: -theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },

  hide: {
    display: 'none',
  },

  drawerPaper: {
    width: drawerWidth,
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
});


class AppBarAdmin extends React.Component {
  
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { showMenu, classes } = this.props;
    const { open } = this.state;
    //{value?<DoneIcon style={{color:'green'}}/>:<ClearIcon style={{color:'red'}}/>}
    return (
      <React.Fragment>
        <AppBar position="static">
          <Toolbar>
            {showMenu && <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>}

            <Typography variant="h6" color="inherit" className={classes.grow}>
              Flipwit - Consola de Administrador            
            </Typography>
            <div className={classes.divLogo}>
              <img src={logo} className={classes.logo} alt="logo" />
            </div>
          </Toolbar>          
        </AppBar>

        <Drawer
            className={classes.drawer}
            /*variant="persistent"*/
            onClose={this.handleDrawerClose}
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem button key={"signOut"} onClick={this.props.closeSession}>
                <ListItemIcon><MeetingRoomIcon /></ListItemIcon>
                <ListItemText primary="Cerrar Sesión" />
              </ListItem>   

              <ListItem button key={"newUser"} onClick={() => {this.handleDrawerClose();this.props.addNewUser();}}>
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                <ListItemText primary="Activar nuevo usuario" />
              </ListItem>              
            </List>            
          </Drawer>
        </React.Fragment>
    );
  }
}

AppBarAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(AppBarAdmin));