import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import { sailsController } from './controller/SailsController'
import { cookieManager } from './controller/CookieManager'

import FwModalLoader from './FwUtils/FwModalLoader';
import FwModalWindow from './FwUtils/FwModalWindow';
import AppBarAdmin from "./AppBarAdmin";



//import './Login.css';

const styles = theme => ({ 

  mainCointainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
  },

  divContent: {
    display: "flex",
    padding: "20px",
    borderRadius: "4px",
    justifyContent: "center",
    backgroundColor: "#eeeeee",    
    flex: 1,
  },

  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing.unit * 4,    
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  divLogo: {
    marginLeft: "auto",
  },

  logo: {
    width: theme.spacing.unit*8,
    height: theme.spacing.unit*8,
  },

  formContainer: {
    marginTop: theme.spacing.unit,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  button: {
    marginTop: theme.spacing.unit * 3,
  }, 
});

class Login extends Component {

  state = {
    name: "",//"umanizales",
    password: "",//"umanizales123",
    errorTextName: "",
    errorTextPass: "",
    showLoader:false,
    modalWindowInfo:undefined,
  }

  componentDidMount() {
    //Si tiene un token guardado va al dashboard
    if(cookieManager.hasCookie('token')) {
      this.props.history.push('/dashboard');
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });    
  };

  onSubmit = (ev) => {
    ev.preventDefault();

    new Promise(
        (resolve, reject) => {

          let errors = {};

          if(!this.state.name) {
            errors.errorTextName = "Por favor completa el campo";      
            reject(errors);
          }

          if(!this.state.password) {
            errors.errorTextPass = "Por favor completa el campo";      
            reject(errors);
          }

          resolve();
        }
    ).then(
      () => {
        const crypto = require('crypto');
        let hash = crypto.createHash('sha1');
        hash.update(this.state.password);

        let hashedPass = hash.digest('hex');

        console.log('hashedPass',hashedPass);

        this.showLoader();

        sailsController.loginAttemp({name:this.state.name, password: hashedPass},
          (data) => {
            //console.log('Llega->',data);

            if(data.success) {
              this.showLoader(false)  
              this.props.history.push('/dashboard');        
            }
            else {
              this.openModalWindow('Error',data.message);
            }
          })
      }
    ).catch(
      (data) => {
        this.setState(data);
        console.log('Falla',data);
      }
    )
  }

  showLoader = (show=true) => this.setState({showLoader:show})

  openModalWindow = (title,message) => {
    this.setState({modalWindowInfo:{title:title,message:message},showLoader:false});    
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainCointainer}>    
        {this.state.modalWindowInfo && <FwModalWindow modalWindow={this.state.modalWindowInfo} onClose={() => this.setState({modalWindowInfo:undefined})}/>}  
        {this.state.showLoader && <FwModalLoader />}
        <AppBarAdmin />
        <div className={classes.divContent}>
          <Paper className={classes.paper} elevation={1}>
              <Typography variant="h5" component="h3">
                Ingresa tus datos de acceso
              </Typography>     
              <form className={classes.formContainer}>                
                <FormControl>              
                  <TextField
                    autoComplete="username"
                    id="standard-name"
                    label="Usuario"
                    error ={!!this.state.errorTextName}
                    helperText={this.state.errorTextName}
                    className={classes.textField}
                    value={this.state.name}
                    onChange={this.handleChange('name')}
                    margin="normal"
                  />
                </FormControl>
                <FormControl>              
                  <TextField
                    id="standard-password"
                    label="Contraseña"
                    error ={!!this.state.errorTextPass}
                    helperText={this.state.errorTextPass}
                    type="password"
                    autoComplete="current-password"
                    className={classes.textField}
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    margin="normal"
                  />                
                </FormControl>
                  <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={(ev) => this.onSubmit(ev)}>
                    Ingresar
                  </Button>
              </form>         
          </Paper>     
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(Login));
